<template>
  <div class="recipes">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div class="recipes__header">
              <div class="recipes__header__top">
                <div v-bind:class="{
                  'recipes__header__title': true,
                  [`recipes__header__title--${titleSize}`]: true,
                }">
                  {{ title || $t('recipes.pageTitle') }}
                </div>

                <div class="recipes__header__buttons">
                  <base-button
                    modifiers="secondary"
                    class="recipes__filterButton"
                    v-on:click="openFilters"
                  >
                    <font-awesome-icon
                      icon="sliders"
                      class="recipes__filterButton__icon"
                    />

                    <span class="recipes__filterButton__textLabel">
                      {{ $t('recipes.searchFiltersButtonLabel') }}

                      <span v-if="numSelectedFilters > 0">
                        &nbsp;({{ numSelectedFilters }})
                      </span>
                    </span>
                  </base-button>

                  <base-button
                    v-if="showFavoritesButton"
                    modifiers="secondary"
                    class="recipes__favoritesButton"
                    v-on:click="mode = mode === 'favorites' ? 'all' : 'favorites'"
                  >
                    <span class="recipes__favoritesButton__textLabel">
                      {{ $t('recipes.favoritesButtonLabel') }}
                    </span>

                    <font-awesome-icon
                      v-bind:icon="[mode === 'favorites' ? 'fas' : 'fal', 'heart']"
                    />
                  </base-button>
                </div>
              </div>

              <search-box
                v-bind:placeholder="$t('recipes.searchInputPlaceholder')"
                v-model="query"
              />

              <p
                v-if="Object.keys(selectedFilter).length"
                class="recipes__resultline"
              >
                <span>
                  {{ $t('recipes.searchFiltersResultLabel') }}
                </span>

                <Chip
                  v-for="(key, index) in Object.keys(selectedFilter)"
                  v-bind:key="index"
                  v-on:click="removeFilter(key)"
                >
                  {{ selectedFilter[key] }}
                </Chip>
              </p>

              <base-alert v-if="error">
                {{ error }}
              </base-alert>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div class="recipes__list">
              <template v-if="loading">
                <skeleton-loader-card
                  v-for="n in items"
                  v-bind:key="n"
                  class="recipes__loadingRecipeCard"
                />
              </template>

              <template v-else>
                <recipe-card
                  v-for="(recipe, index) in results"
                  v-bind:key="index"
                  v-bind:recipe="recipe"
                  class="recipes__recipeCard"
                />
              </template>

              <base-button
                v-if="recipes.length && showShowMore"
                v-bind:show-spinner="loading"
                modifiers="secondary block fullWidth"
                v-on:click="showMore"
              >
                {{ $t('recipes.moreRecipesButtonLabel') }}
              </base-button>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <router-view />
  </div>
</template>

<script>
import { defineAsyncComponent, toRaw, markRaw } from 'vue';
import { mapState, mapActions } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import SearchBox from '@/components/SearchBox';
import BaseAlert from '@/components/BaseAlert';
import Chip from '@/components/Chip';
import RecipeCard from '@/components/recipes/RecipeCard';
import BaseButton from '@/components/BaseButton';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import { ModalBus } from '@/eventBus';

const RecipeFilterModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/recipes/RecipeFilterModal' /* webpackChunkName: "recipeFilterModal" */),
}));

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    SkeletonLoaderCard,
    SearchBox,
    BaseButton,
    BaseAlert,
    Chip,
    RecipeCard,
  },

  props: {
    title: {
      type: String,
    },

    titleSize: {
      type: String,
      default: 'heading-2',
    },

    showFavoritesButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      error: false,
      loading: true,
      items: 30,
      recipes: [],
      mode: 'all',
      currentPage: 1,
      showShowMore: true,
      resultAmount: 0,
    };
  },

  computed: {
    ...mapState('recipe', ['selectedFilter']),

    numSelectedFilters() {
      return Object.keys(this.selectedFilter).length;
    },

    query: {
      get() {
        return this.$route.query.q || '';
      },

      set(value) {
        this.$router
          .replace({ query: { ...this.$route.query, q: value } })
          .catch(e => e);
      }
    },

    type() {
      return this.$route.query.type;
    },

    course() {
      return this.$route.query.course;
    },

    program() {
      return this.$route.query.program;
    },

    triggerParams() {
      return JSON.stringify({
        input: this.query,
        type: this.type,
        course: this.course,
        program: this.program,
      });
    },

    searchParams() {
      return JSON.stringify({
        input: this.query,
        type: this.type,
        course: this.course,
        program: this.program,
        page: this.currentPage,
      });
    },

    results() {
      return this.recipes;
    }
  },

  watch: {
    triggerParams: {
      handler: 'debouncedSearch',
    },

    mode: {
      handler: 'modeChange',
    },

    '$store.state.recipe.selectedFilter'() {
      this.applyFilter();
    },
  },

  mounted() {
    const query = { ...this.$route.query };
    const hasSearchQueries = Object.entries(query).length > 0;

    if (hasSearchQueries) {
      this.debouncedSearch();
    } else {
      this.getAll();
    }
  },

  beforeUnmount() {
    this.$store.commit('recipe/resetFilter');
  },

  methods: {
    ...mapActions({
      searchRecipes: 'recipe/search',
      getAllRecipes: 'recipe/getAll',
      getAllFavoritesRecipes: 'recipe/getFavorites',
    }),

    modeChange() {
      this.loading = true;

      // reset all values
      this.currentPage = 1;
      this.recipes = [];
      this.error = false;

      // reset searchqueries
      const query = { ...this.$route.query };
      const hasSearchQueries = Object.entries(query).length > 0;

      if (this.mode !== 'search' && hasSearchQueries) {
        delete query.q;
        this.$router.push({ query }).catch(() => {});
      }

      switch (this.mode) {
        case 'favorites':
          this.getFavorites();
          break;

        case 'search':
          // searchfunction is set in debouncedSearch()
          return;
        default:
          this.getAll();
      }
    },

    async getAll() {
      const recipeData = await this.getAllRecipes(this.currentPage);

      this.recipes = [...this.recipes, ...recipeData.data];
      this.showShowMore = this.currentPage !== recipeData.last_page;
      this.loading = false;
    },

    debouncedSearch() {
      const filterValuesEmpty =
        !this.query && !this.type && !this.course && !this.program;

      if (this.mode === 'favorites' && filterValuesEmpty) {
        // if mode is set to favorites and there are no search or filter values -> don't change to all recipes for fuck sake.
        return;
      }

      if (filterValuesEmpty) {
        // if there are no search or filter values -> Set to all recipes
        this.mode = 'all';
        return;
      }

      if (this.$route.name !== 'recipeSingle') {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          // timeout so we don't start searching on every keystroke, but after a small pause
          this.search();
        }, 500);
      }
    },

    async search(showmore) {
      this.mode = 'search';

      if (!showmore) {
        this.currentPage = 1;
        this.recipes = [];

        this.error = false;
      }

      const params = this.searchParams;

      let error, recipes;

      try {
        recipes = await this.searchRecipes(JSON.parse(params));
      } catch (err) {
        console.warn(err);
        error = err;
      }

      this.loading = false;

      if (params !== this.searchParams) {
        return; // race condition
      }

      if (error) {
        this.error = error.message || this.$t('common.oops');
        throw error;
      } else {
        this.recipes = [...this.recipes, ...recipes.recipes];

        if (this.currentPage === recipes.last_page) {
          this.showShowMore = false;
        }
      }
    },

    async getFavorites() {
      const recipeData = await this.getAllFavoritesRecipes(this.currentPage);

      this.recipes = [...this.recipes, ...recipeData.data];
      this.showShowMore = this.currentPage !== recipeData.last_page;
      this.loading = false;
    },

    async showMore() {
      this.loading = true;
      this.currentPage++;

      if (this.mode === 'search') {
        await this.search(true);
      } else if (this.mode === 'favorites') {
        await this.getFavorites();
      } else {
        await this.getAll();
      }

      this.loading = false;
    },

    openFilters() {
      ModalBus.emit('open', {
        component: RecipeFilterModal,
      });
    },

    applyFilter() {
      this.$router
        .replace({ query: { ...this.selectedFilter } })
        .catch(error => {});
    },

    removeFilter(key) {
      const selectedFilter = { ...toRaw(this.selectedFilter) };
      delete selectedFilter[key];

      this.$store.commit('recipe/setSelectedFilter', { ...selectedFilter });
      this.applyFilter();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipes {
  margin: 1.25rem auto;
}

.recipes__header {
  margin: 0 0 1rem 0;
}

.recipes__header__title {
  color: $color-text;

  &--heading-2 {
    @include heading-2;
  }

  &--heading-4 {
    @include heading-4;
  }
}

.recipes__header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem 0;
}

.recipes__filterButton {
  margin: 0 0.5rem 0 0;
}

.recipes__filterButton__icon {
  @include desktop {
    display: none;
  }
}

.recipes__filterButton__textLabel {
  display: none;

  @include desktop {
    display: inline;
  }
}

.recipes__favoritesButton__textLabel {
  display: none;
  margin: 0 $spacing-xs 0 0;

  @include desktop {
    display: inline;
  }
}

.recipes__resultline {
  margin: 1rem 0 0 0;
}

.recipes__list {
  @include desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.recipes__loadingRecipeCard,
.recipes__recipeCard {
  margin: 0 0 1rem 0;

  @include desktop {
    flex: 0 0 calc(33.3% - 0.66rem);
    margin: 0 0 2rem 0;
  }
}

@media print {
  .recipes {
    display: none;
  }
}
</style>
