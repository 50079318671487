<template>
  <div
    v-bind:class="{
      'recipeCard': true,
      'recipeCard--program': inSlider,
    }"
    v-on:click="onClick"
  >
    <div
      class="recipeCard__image"
      v-bind:style="{ backgroundImage: `url('${backgroundImage}?w=400&q=80')` }"
      v-bind:title="recipe.name"
    >
      <font-awesome-icon
        class="icon-favorite"
        v-bind:icon="[favorite ? 'fas' : 'fal', 'heart']"
      />
    </div>

    <div class="recipeCard__content">
      <div class="recipeCard__title">
        {{ recipe.name }}
      </div>

      <div
        v-if="recipe.prep_time"
        class="recipeCard__preparationTime"
      >
        <font-awesome-icon
          icon="clock"
          class="recipeCard__preparationTimeIcon"
        />

        {{ $t('recipes.preparationTimeMinutes', { time: recipe.prep_time }) }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapState } from 'vuex';
import { ModalBus } from '@/eventBus';

const RecipeModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/recipes/RecipeModal' /* webpackChunkName: "recipeModal" */),
}));

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },

    inSlider: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('recipe', ['favorites']),

    favorite() {
      return this.favorites[this.recipe.id];
    },

    backgroundImage() {
      const { image, poster } = this.recipe;

      return image ? image : poster;
    },
  },

  methods: {
    onClick() {
      ModalBus.emit('open', {
        component: RecipeModal,
        modifiers: 'recipe',
        props: {
          recipeId: this.recipe.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipeCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &--program {
    display: block;
  }

  @include desktop {
    display: block;
  }
}

.recipeCard__image {
  flex: 0 0 calc(50% - 1rem);
  position: relative;
  height: 5.75rem;
  border-radius: $border-radius;
  background-color: $color-beige;
  background-size: cover;
  background-position: center center;

  .recipeCard--program & {
    height: 10rem;
  }

  @media (min-width: $breakpoint-md) {
    height: 11rem;
  }

  .icon-favorite {
    color: $color-white;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.recipeCard__content {
  flex: 0 0 50%;
}

.recipeCard__title {
  @include small;
  margin-top: 0.75rem;
}

.recipeCard__preparationTime {
  @include label;
}

.recipeCard__preparationTimeIcon {
  color: $color-beige--dark;
  margin: 0.25rem 5px 0 0;
  font-size: 1rem;
}
</style>
