<template>
  <span
    class="chip"
    v-on:click="$emit('click', $event)"
  >
    <slot />
    <font-awesome-icon icon="xmark" />
  </span>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.chip {
  margin: 0 0 0 6px;
  padding: 4px 10px;
  min-height: auto;
  width: auto;
  background: $color-beige;
  border-radius: 3px;
  cursor: pointer;

  .svg-inline--fa {
    margin: 0 0 0 4px;
    width: 0.5em;
    height: auto;
    opacity: 0.5;
  }
}
</style>
