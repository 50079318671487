<template>
  <div class="searchbox input-wrapper">
    <font-awesome-icon icon="magnifying-glass" />

    <input
      type="search"
      v-bind:value="modelValue"
      v-bind:placeholder="placeholder"
      class="input"
      v-on:input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.searchbox {
  position: relative;
  margin: 0 0 8px;

  .svg-inline--fa {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  input[type="search"] {
    text-indent: 36px;
  }
}

input:not([type="range"]).input {
  @include small;
  padding: 0 8px 0 0;
  min-height: 42px;
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid #c9ced4;
}
</style>
